<template>
  <v-row align="center" class="pipeline-row pl-6">
    <v-col cols="12" class="pb-0">
      <v-row align="center" class="flex-nowrap pipeline-arrow pb-0">
        <v-col :cols="length" :class="`arrow arrow-${color}`" :style="getStyle()">
          {{ title }}
        </v-col>
        <div :class="`pointer pointer-${color}`"></div>
      </v-row>
      <v-row>
        <v-col class="text-left pa-0 pl-1 text-subtitle-1 font-weight-medium">
          {{ subtitle }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Arrow',
  props: {
    color: String,
    length: Number || String,
    title: String,
    subtitle: String,
    overrideWidth: String
  },
  methods: {
    getStyle() {
      if (!this.overrideWidth) {
        return '';
      } else {
        const OW = this.overrideWidth;
        return `flex: 0 0 ${OW}; max-width: ${OW};`;
      }
    }
  }
}
</script>

<style scoped lang="scss">
$pointer-size: 25px;

.pipeline {
  &-arrow {
    padding-right: 6px;
    text-align: left;
    align-items: center;
  }
}
.arrow {
  color: white;
  font-size: 1.35rem;
  margin-right: 3px;
  &-biologic {
    background: var(--v-biologic-base);
  }
  &-molecule {
    background: var(--v-molecule-base);
    background: linear-gradient(
      90deg,
      var(--v-molecule-base) 50%,
      var(--v-molecule-dark-base) 100%
    );
  }
  &-gene {
    background: var(--v-gene-base);
    background: linear-gradient(
      90deg,
      var(--v-gene-base) 50%,
      var(--v-gene-dark-base) 100%
    );
  }
  &-rna {
    background: var(--v-rna-base);
    background: linear-gradient(
      90deg,
      var(--v-rna-base) 50%,
      var(--v-rna-dark-base) 100%
    );
  }
}
.pointer {
  padding: 0;
  width: 0;
  height: 0;
  border-top: $pointer-size solid transparent;
  border-bottom: $pointer-size solid transparent;
  &-biologic {
    border-left: $pointer-size solid var(--v-biologic-base);
  }
  &-molecule {
    border-left: $pointer-size solid var(--v-molecule-base);
  }
  &-gene {
    border-left: $pointer-size solid var(--v-gene-base);
  }
  &-rna {
    border-left: $pointer-size solid var(--v-rna-base);
  }
}
</style>
