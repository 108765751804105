import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import minifyTheme from 'minify-css-string'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
      minifyTheme,
      variations: false,
    },
    themes: {
      light: {
        primary: '#673065',
        header: '#e5e1e6',
        secondary: '#d8dfe1',
        biologic: '#80bc00',
        molecule: '#c017a2',
        'molecule-dark': '#c017a2',
        gene: '#4c85a0',
        'gene-dark': '#4c85a0',
        rna: '#f88e2a',
        'rna-dark': '#f88e2a',
      },
    },
  },
})
