<template>
  <v-app-bar
    color="header"
    dark
    flat
    fixed
    extension-height="70"
    hide-on-scroll
    height="90"
    app
    src="/img/logo.png"
  >
    <template #img="{ props }">
      <img
        v-bind="props"
        class="pl-3 pt-3 pr-1 pb-1"
        height="85px"
        alt="Ultragenyx logo"
      />
    </template>

    <v-row align="center">
      <v-col cols="8" offset="2" class="pa-0 pt-1">
        <v-toolbar-title class="primary--text text-uppercase pipeline-title">
          {{ title }}
        </v-toolbar-title>
        <div class="primary--text pipeline-subtitle pa-0">
          Welcome to the Ultragenyx Medical Affairs Interactive Pipeline.
        </div>
      </v-col>
      <v-col cols="2" class="primary--text text-center">
        Click the ? button in the lower righthand corner for instructions.
      </v-col>
    </v-row>

    <template #extension>
      <v-row>
        <v-col
          align-self="center"
          v-for="(phase, i) in phases"
          :key="i"
          class="pa-0 pipline-part pipline-phase"
        >
          <div class="pipeline-header" v-html="phase"></div>
        </v-col>
      </v-row>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  name: 'PipelineHeader',
  props: {
    hasToured: Boolean,
  },
  data: () => ({
    title: 'Clinical and Preclinical Pipeline',
    phases: [
      'Candidate',
      'Pre-clinical',
      'Phase 1',
      'Phase 2',
      'Phase 3',
      'Regulatory<br/>Review',
      'Approved',
    ],
  }),
}
</script>

<style lang="scss">
.v-toolbar__extension {
  background-color: var(--v-primary-base);
}
.pipeline {
  &-header {
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.15;
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &-title {
    font-weight: bold;
    font-size: 2.6rem;
    text-align: center;
    line-height: 1.15;
  }
  &-instructions {
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.15;
  }
  &-subtitle {
    text-align: center;
    line-height: 1.15;
  }
}
.pipline-phase.col:not(:last-child) {
  border-right: 2px solid white;
  box-sizing: border-box;
}
.pipline-phase.col:not(:first-child) {
  border-left: 2px solid white;
  box-sizing: border-box;
}
</style>
