import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import kiosk from './kiosk'
import VueGtag from 'vue-gtag'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')

window.$temp = process.env.VUE_APP_ANALYTICS_ID

Vue.use(VueTour)

if (process.env.NODE_ENV === 'production') {
  Vue.use(VueGtag, {
    config: {
      id: process.env.VUE_APP_ANALYTICS_ID,
      params: {
        app_name: 'Ultragenyx Pipeline',
      },
    },
  })
}

Vue.config.productionTip = false

Vue.prototype.$config = kiosk.config

new Vue({
  vuetify,
  render: function(h) {
    return h(App)
  },
}).$mount('#app')
