<template>
  <div class="clickable" @click="$emit('click')">
    <divider v-if="!first"></divider>
    <v-row class="mx-0 pipeline-row">
      <v-col align-self="center" class="pipeline pipeline-part px-5">
        <v-tooltip v-model="definition" right transition="slide-x-transition">
          <template #activator="{ on }">
            <div
              class="pipeline-candidate"
              @click.stop="definition = !definition"
              v-on="on"
            >
              <div class="pb-1" v-html="data.title"></div>
              <v-img
                v-if="data.img"
                :src="`/img/${data.img}?nf_resize=fit&w=100`"
                width="100px"
                style="margin: 0 auto;"
              ></v-img>
              <div class="pipeline-subtitle font-weight-regular" v-html="data.subtitle">
              </div>
            </div>
          </template>
          <div class="pipeline-definition" v-html="data.definition"></div>
        </v-tooltip>
      </v-col>
      <v-hover #default="{ hover }">
        <v-col align-self="stretch" class="pipeline pipeline-main bg-white">
          <arrow v-bind="data.pipeline"></arrow>
          <v-scroll-y-transition>
            <div v-show="hover" class="pb-1 mt-n9">
              <v-btn
                v-for="(dialog, i) in data.modal.dialogs"
                :key="i"
                small
                class="white--text mx-2"
                :color="data.pipeline.color"
                @click.stop="$emit('select', { id: data.id, dialog: dialog })"
              >
                {{ dialog }}
              </v-btn>
              <v-alert
                v-for="(alert, i) in data.modal.alerts"
                :key="i"
                small
                class="white--text mx-2 py-1"
                :color="data.pipeline.color"
              >
                {{ alert }}
              </v-alert>
            </div>
          </v-scroll-y-transition>
        </v-col>
      </v-hover>
      <v-col align-self="center" class="pipeline pipeline-part">
        <v-tooltip
          v-model="approved"
          left
          transition="slide-x-reverse-transition"
          v-if="data.approved"
        >
          <template #activator="{ on }">
            <div
              class="approved"
              v-html="data.approved"
              @click.stop="approved = !approved"
              v-on="on"
            ></div>
          </template>
          <div class="pipeline-definition" v-html="data.approvedYears"></div>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Divider from './Divider'
import Arrow from './Arrow'

export default {
  name: 'PipelineRow',
  props: {
    data: Object,
    active: Boolean,
  },
  computed: {
    first() {
      return this.data.id == 1
    },
  },
  data: () => ({
    definition: false,
    approved: false,
  }),
  components: {
    Divider,
    Arrow,
  },
}
</script>

<style lang="scss">
.pipeline {
  padding: 0;
  color: black;
  font-size: 1.15rem;
  font-weight: 600;
  line-height: 1.25;
  align-items: center;
  justify-content: center;
  text-align: center;
  &-row {
    min-height: 160px;
    cursor: pointer;
  }
  &-part {
    flex: 0 0 14.29%;
    max-width: 14.29%;
  }
  &-main {
    flex: 0 0 71.42%;
    max-width: 71.42%;
  }
  &-subtitle {
    font-size: 1rem;
    line-height: 1.15;
  }
  &-definition {
    font-size: 1.1rem;
  }
}

.approved {
  font-size: 1rem;
  width: fit-content;
  margin: 0 auto;
}

.v-tooltip__content {
  max-width: 25% !important;
}
</style>
