<template>
  <v-container fluid class="pa-0">
    <v-item-group>
      <div v-for="(row, i) in rows" :key="row.id">
        <v-item v-slot:default="{ active, toggle }">
          <pipeline-row
            :data="row"
            :active="active"
            @select="selected = $event"
            @click="toggle"
          ></pipeline-row>
        </v-item>
        <v-tour
          v-if="i == 0"
          name="myTour"
          :steps="steps"
          :options="tourConfig"
          :callbacks="{
            onFinish: finishTour,
            onSkip: skipTour,
            onPreviousStep: scrollTop,
          }"
        ></v-tour>
      </div>
    </v-item-group>
    <v-fade-transition>
      <v-btn
        v-show="showHelpBtn"
        :class="hasToured ? '' : 'pulse'"
        color="primary"
        dark
        fixed
        bottom
        right
        fab
        aria-label="Help"
        @click="startTour"
      >
        <v-icon>{{ helpIcon }}</v-icon>
      </v-btn>
    </v-fade-transition>
    <pipeline-footer @open-dialog="openGTDialog"></pipeline-footer>
    <app-footer v-intersect="onIntersect"></app-footer>
    <v-dialog :value="selectedDialog" fullscreen scrollable>
      <modal
        v-if="selectedDialog"
        :item="selectedItem"
        :dialog="selectedDialog"
        @close="closeDialog"
      ></modal>
    </v-dialog>
  </v-container>
</template>

<script>
import AppFooter from '@/components/AppFooter'
import PipelineRow from '@/components/PipelineRow'
import PipelineFooter from '@/components/PipelineFooter'
import Modal from '@/components/Modal'
import pipeline from '../pipeline'
import { mdiHelpCircleOutline } from '@mdi/js'

export default {
  name: 'Home',
  components: {
    AppFooter,
    PipelineRow,
    PipelineFooter,
    Modal,
  },
  props: {
    hasToured: Boolean,
  },
  data: () => ({
    showHelpBtn: true,
    helpIcon: mdiHelpCircleOutline,
    selected: undefined,
    selectedItem: undefined,
    selectedDialog: undefined,
    rows: pipeline.rows,
    tourConfig: {
      highlight: true,
      labels: {
        buttonSkip: 'Dismiss',
        buttonPrevious: 'Previous',
        buttonNext: 'Next',
        buttonStop: 'Dismiss',
      },
    },
    steps: [
      {
        target: '.pipeline-candidate',
        content: 'Hover or click on each candidate to see its definition.',
        params: {
          placement: 'right',
          enableScrolling: false,
        },
      },
      {
        target: '.pipeline-main',
        content: 'Hover or click on each arrow section to learn more.',
        params: {
          placement: 'bottom',
          enableScrolling: false,
        },
      },
      {
        target: '.approved',
        content:
          'Hover or click on approved countries to see their approval year.',
        params: {
          placement: 'left',
          enableScrolling: false,
        },
      },
      {
        target: '.footer-arrow .arrow-gene',
        content: 'Click here to view the Gene Therapy brochure',
        params: {
          placement: 'top',
        },
      },
    ],
  }),
  watch: {
    selected: {
      handler() {
        this.$tours['myTour'].skip()
        const id = this.selected ? this.selected.id : this.selected
        this.selectedItem = this.rows.find((row) => row.id === id)
        if (this.selectedItem) {
          this.selectedDialog = this.selectedItem.modal.dialogs.find(
            (dialog) => dialog === this.selected.dialog
          )
        } else {
          this.selectedDialog = undefined
        }
      },
    },
  },
  methods: {
    scrollTop() {
      this.$vuetify.goTo(0)
    },
    closeDialog() {
      this.selected = undefined
      this.selectedItem = undefined
      this.selectedDialog = undefined
    },
    startTour() {
      this.scrollTop()
      this.showHelpBtn = false
      this.$tours['myTour'].start()
      if (this.$gtag) {
        this.$gtag.event('TOUR_START', {
          event_category: 'Tour',
        })
      }
    },
    skipTour() {
      this.exitTour()
      if (this.$gtag) {
        this.$gtag.event('TOUR_SKIP', {
          event_category: 'Tour',
        })
      }
    },
    finishTour() {
      this.$emit('tour-complete')
      localStorage.setItem('tour-complete', true)
      this.exitTour()
      if (this.$gtag) {
        this.$gtag.event('TOUR_FINISH', {
          event_category: 'Tour',
        })
      }
    },
    exitTour() {
      this.scrollTop()
      this.showHelpBtn = true
    },
    onIntersect(entries, observer) {
      this.showHelpBtn = !entries[0].isIntersecting
    },
    openGTDialog() {
      this.$tours['myTour'].skip()
      let geneTherapy = {
        pipeline: { color: 'gene' },
        modal: {
          title: 'Gene Therapy',
          content: [
            {
              dialog: 'Brochure',
              length: 6,
              name: 'GT_Brochure',
              type: 'document',
            },
          ],
        },
      }
      this.selectedItem = geneTherapy
      this.selectedDialog = 'Brochure'
    },
  },
}
</script>

<style lang="scss">
.v-tour__target--highlighted {
  box-shadow: 0 0 0 4px #673065 !important;
}
.v-step {
  background-color: #673065 !important;
  &[x-placement^='right'] {
    .v-step__arrow {
      border-right-color: #673065 !important;
    }
  }
  &[x-placement^='left'] {
    .v-step__arrow {
      border-left-color: #673065 !important;
    }
  }
  &[x-placement^='bottom'] {
    .v-step__arrow {
      border-bottom-color: #673065 !important;
    }
  }
}
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-delay: 1s;
  animation-iteration-count: 5;
}


</style>
