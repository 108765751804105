<template>
  <v-col class="footer-arrow">
    <v-row
      align="center"
      class="flex-nowrap pipeline-arrow pb-0"
      @click="click"
    >
      <v-col
        cols="12"
        :class="`d-flex py-1 px-0 arrow arrow-${color}`"
        v-html="name"
      ></v-col>
      <div :class="`pointer pointer-${color}`"></div>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'FooterArrow',
  props: {
    color: String,
    name: String,
  },
  methods: {
    click() {
      if (this.name === 'Gene Therapy') {
        this.$emit('click')
      }
    },
  },
}
</script>

<style scoped lang="scss">
$pointer-size: 22px;

.footer-arrow {
  flex: 0 0 13%;
  max-width: 13%;
  margin-right: 50px;
}

.arrow {
  color: white;
  font-weight: 600;
  line-height: 1.1;
  min-height: 45px;
  margin-right: 3px;
  text-align: center;
  justify-content: center;
  align-items: center;
  &-biologic {
    background-color: var(--v-biologic-base);
  }
  &-molecule {
    background-color: var(--v-molecule-base);
  }
  &-gene {
    cursor: pointer;
    background-color: var(--v-gene-base);
    border: 2px solid var(--v-gene-base);
    transition: all 0.3s linear;
    &:hover {
      background-color: white;
      color: var(--v-gene-base);
    }
  }
  &-rna {
    background-color: var(--v-rna-base);
  }
}
.pointer {
  padding: 0;
  width: 0;
  height: 0;
  border-top: $pointer-size solid transparent;
  border-bottom: $pointer-size solid transparent;
  &-biologic {
    border-left: $pointer-size solid var(--v-biologic-base);
  }
  &-molecule {
    border-left: $pointer-size solid var(--v-molecule-base);
  }
  &-gene {
    border-left: $pointer-size solid var(--v-gene-base);
  }
  &-rna {
    border-left: $pointer-size solid var(--v-rna-base);
  }
}
</style>
