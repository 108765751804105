<template>
  <v-app id="app">
    <pipeline-header v-if="loggedIn" :hasToured="hasToured"></pipeline-header>
    <v-main>
      <v-alert
        type="warning"
        class="mb-0"
        v-if="$vuetify.breakpoint.width < 1050"
      >
        The Ultragenyx Pipeline was designed for use on desktop or laptop
        computers. The site may not work correctly on mobile devices or smaller
        screens. Thank you for your understanding
      </v-alert>
      <home v-if="loggedIn" @tour-complete="hasToured = true" :hasToured="hasToured"></home>
      <v-dialog fullscreen persistent :value="!loggedIn">
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="center">
            <v-col md="4">
              <v-card>
                <v-toolbar
                  color="primary"
                  dark
                  flat
                >
                  <v-toolbar-title>Please Log In</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-form @submit="submitLogin">
                    <v-text-field
                      id="password"
                      label="Password"
                      name="password"
                      :prepend-icon="lock"
                      type="password"
                      v-model="password"
                      :error-messages="loginError"
                    ></v-text-field>
                  </v-form>
                <p class="text-center">Please contact <a href="mailto:MedInfo@Ultragenyx.com">MedInfo@Ultragenyx.com</a> to request access</p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="submitLogin">Login</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import Home from './views/Home'
import PipelineHeader from '@/components/PipelineHeader'
import { mdiLock } from '@mdi/js'
import auth from '@/mixins/auth'

export default {
  name: 'App',
  mixins: [auth],
  components: {
    Home,
    PipelineHeader,
  },
  data: () => ({
    hasToured: false,
    lock: mdiLock,
    password: ""
  }),
  methods: {
    submitLogin(event) {
      event.preventDefault()
      this.login(this.password)
    }
  },
  mounted() {
    window.$app = this;
    this.hasToured = localStorage.getItem('tour-complete') === 'true'
    console.log(process.env.VUE_APP_VERSION || '0')
  },
}
</script>

<style lang="scss">
#app {
  background: var(--v-header-base);
}
.bg-white {
  background: white;

}
</style>
