<template>
  <v-row class="mx-0 divider-row">
    <v-col class="pipeline-part pa-0">
      <hr class="divider" />
    </v-col>
    <v-col class="pipeline-main pa-0 bg-white">
      <hr class="divider divider-dark" />
    </v-col>
    <v-col class="pipeline-part pa-0">
      <hr class="divider" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Divider',
  data: () => ({})
}
</script>

<style scoped lang="scss">
.divider {
  border: 2px solid white;
  margin: 0 10px;
  &-dark {
    border-color: #8b8d90;
  }
}
</style>
