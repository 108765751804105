
export default {
  data: () => ({
    loggedIn: false,
    loginError: ""
  }),
  methods: {
    checkLogin() {
      if (this.loggedInConditionsMet()) {
        this.setLoggedin()
      }
    },
    loggedInConditionsMet() {
      return (
        this.loggedIn ||
        this.validToken() ||
        sessionStorage.getItem(`loggedIn${this.$config.marcNum}`) === 'true' ||
        process.env.NODE_ENV === 'development'
      )
    },
    validToken() {
      const token = this.getDecodedToken()
      if (token) {
        return this.validPassword(token)
      } else {
        return false
      }
    },
    getDecodedToken() {
      let query = document.location.search.match(/\?(?<token>.+)/)
      if (query && query.groups !== undefined) {
        return atob(query.groups.token)
      }
    },
    validPassword(password) {
      return password === this.$config.sitePW
    },
    login(password) {
      if (this.validPassword(password)) {
        this.setLoggedin()
      } else {
        this.loginError = "Incorrect Password"
      }
    },
    setLoggedin() {
      sessionStorage.setItem(`loggedIn${this.$config.marcNum}`, true)
      this.loggedIn = true
      this.loginError = ""
    },
  },
  mounted() {
    if (this.$config.useAuth) {
      this.checkLogin()
    } else {
      this.loggedIn = true
    }
  },
}
