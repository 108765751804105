<template>
  <v-row class="mx-0">
    <v-col class="pipeline-part"></v-col>
    <v-col class="pipeline-main pipeline-footer">
      <v-row class="pl-5 pt-10">
        <footer-arrow
          v-for="arrow in arrows"
          :key="arrow.id"
          v-bind="arrow"
          @click="$emit('open-dialog')"
        ></footer-arrow>
      </v-row>
      <v-row justify="space-around" class="pb-0 mb-0">
        <v-col cols="auto" sm="6" class="pr-0">
          <ul>
            <li>AAVhu37-FVIII = AAV serotype human37 factor 8</li>
            <li>AAV8 = adeno-associated virus serotype 8</li>
            <li>AAV9 = adeno-associated virus serotype 9</li>
            <li>ANGPTL3 = angiopoietin-like 3</li>
            <li>ASO = antisense oligonucleotide</li>
            <li>ATP7B = copper transporting P-type ATPase</li>
            <li>CDKL5 = cyclin dependent kinase-like 5</li>
            <li>FGF23 = fibroblast growth factor 23</li>
            <li>G6Pase = glucose-6-phosphatase</li>
            <li>LNP = lipid nanoparticle</li>
            <li>mRNA = messenger ribonucleic acid</li>
            <li>SGSH = N-sulphoglucosamine sulphohydrolase</li>
          </ul>
        </v-col>
        <v-col cols="auto" sm="6">

        </v-col>
      </v-row>
      <v-row justify="center" class="mt-1 pb-3">
        <v-col class="mt-0 pt-0">
          <sup class="mr-0">1</sup> Ultragenyx licensed ex-US rights to evinacumab from Regeneron.<br>
          <sup class="mr-0">2</sup> Excludes the US, where Regeneron has rights.<br>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import FooterArrow from "./FooterArrow";

export default {
  name: "PipelineFooter",
  components: {
    FooterArrow,
  },
  data: () => ({
    arrows: [
      {
        id: 1,
        name: "Protein<br/>Biologic",
        color: "biologic",
      },
      {
        id: 2,
        name: "Small<br/>Molecule",
        color: "molecule",
      },
      {
        id: 3,
        name: "Gene Therapy",
        color: "gene",
      },
      {
        id: 4,
        name: "ASO/mRNA",
        color: "rna",
      },
    ],
  }),
};
</script>

<style scoped lang="scss">
$pointer-size: 30px;

ul {
  list-style-type: none;
  padding-left: 0;
  font-size: 0.93rem;
  line-height: 1.1;
}

.pipeline {
  &-footer {
    background: white;
  }
}
.arrow {
  color: white;
  font-weight: bold;
  line-height: 1.1;
  min-height: 58px;
  margin-right: 3px;
  text-align: center;
  justify-content: center;
  align-items: center;
  &-biologic {
    background: var(--v-biologic-base);
  }
  &-molecule {
    background: var(--v-molecule-base);
  }
  &-gene {
    background: var(--v-gene-base);
  }
  &-rna {
    background: var(--v-rna-base);
  }
}
.pointer {
  padding: 0;
  width: 0;
  height: 0;
  border-top: $pointer-size solid transparent;
  border-bottom: $pointer-size solid transparent;
  &-biologic {
    border-left: $pointer-size solid var(--v-biologic-base);
  }
  &-molecule {
    border-left: $pointer-size solid var(--v-molecule-base);
  }
  &-gene {
    border-left: $pointer-size solid var(--v-gene-base);
  }
  &-rna {
    border-left: $pointer-size solid var(--v-rna-base);
  }
}
</style>
