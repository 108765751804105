var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-item-group',_vm._l((_vm.rows),function(row,i){return _c('div',{key:row.id},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('pipeline-row',{attrs:{"data":row,"active":active},on:{"select":function($event){_vm.selected = $event},"click":toggle}})]}}],null,true)}),(i == 0)?_c('v-tour',{attrs:{"name":"myTour","steps":_vm.steps,"options":_vm.tourConfig,"callbacks":{
          onFinish: _vm.finishTour,
          onSkip: _vm.skipTour,
          onPreviousStep: _vm.scrollTop,
        }}}):_vm._e()],1)}),0),_c('v-fade-transition',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHelpBtn),expression:"showHelpBtn"}],class:_vm.hasToured ? '' : 'pulse',attrs:{"color":"primary","dark":"","fixed":"","bottom":"","right":"","fab":"","aria-label":"Help"},on:{"click":_vm.startTour}},[_c('v-icon',[_vm._v(_vm._s(_vm.helpIcon))])],1)],1),_c('pipeline-footer',{on:{"open-dialog":_vm.openGTDialog}}),_c('app-footer',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}]}),_c('v-dialog',{attrs:{"value":_vm.selectedDialog,"fullscreen":"","scrollable":""}},[(_vm.selectedDialog)?_c('modal',{attrs:{"item":_vm.selectedItem,"dialog":_vm.selectedDialog},on:{"close":_vm.closeDialog}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }