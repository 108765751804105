<template>
  <v-card>
    <v-toolbar flat :color="item.pipeline.color" dark>
      <v-toolbar-title class="text-h5">
        {{ item.modal.title }} --
        <span class="text-capitalize">{{ dialog }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        dark
        color="white"
        @click="$emit('close')"
        aria-label="Go Home"
      >
        <v-icon>{{ closeIcon }}</v-icon>
      </v-btn>

      <template #extension v-if="hasTabs">
        <v-tabs
          fixed-tabs
          dark
          :background-color="item.pipeline.color"
          :color="item.pipeline.color == 'biologic' ? 'primary' : 'biologic'"
          v-model="tabs"
        >
          <v-tab v-for="tab in content" :key="tab.tab">
            {{ tab.tab }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-card-text class="pa-0">
      <v-tabs-items v-model="tabs" v-if="hasTabs">
        <v-tab-item v-for="(tab, i) in content" :key="i">
          <component
            :is="tab.type"
            v-bind="tab"
            :tab-color="item.pipeline.color"
            :visible="tabs === i"
            @view-page="viewPage"
          ></component>
        </v-tab-item>
      </v-tabs-items>
      <component
        v-else
        :is="content[0].type"
        v-bind="content[0]"
        :tab-color="item.pipeline.color"
        visible
        @view-page="viewPage"
      ></component>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiHome } from '@mdi/js'

export default {
  name: 'modal',
  props: {
    item: Object,
    dialog: String,
  },
  data() {
    return {
      tabs: 0,
      page: '',
      closeIcon: mdiHome,
    }
  },
  components: {
    TrialCards: () => import('./TrialCards'),
    Document: () => import('./Document'),
    VideoPlayer: () => import('./VideoPlayer'),
  },
  computed: {
    content() {
      let content = []
      for (let i = 0; i < this.item.modal.content.length; i++) {
        const element = this.item.modal.content[i]
        if (element.dialog === this.dialog) {
          content.push(element)
        }
      }
      return content
    },
    hasTabs() {
      return this.content.length > 1
    },
    screenviewTitle() {
      return this.item.modal.title
    },
    screenviewSection() {
      return this.dialog
    },
    screenviewTab() {
      let currentTab = this.content[this.tabs]
      return currentTab.tab ? currentTab.tab : ''
    },
    screenviewPage() {
      let page = ''
      if (this.hasTabs) {
        page = page + `${this.screenviewTab} -- `
      }
      return page + this.page
    },
    screenName() {
      return `${this.screenviewTitle} / ${this.screenviewSection} / ${this.screenviewPage}`
    },
  },
  methods: {
    viewPage(page) {
      this.page = page
      this.track()
    },
    track() {
      if (this.$gtag) {
        this.$gtag.pageview({
          page_title: this.screenName,
        })
      } else {
        console.log(this.screenName)
      }
    },
  },
}
</script>

<style lang="scss">
</style>